import React, { createContext, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useCurrentUserQuery } from "@/react/queries/mgmt-api/users/current-user";
import { User } from "@/react/types/users/user";
import FullScreenLoader from "@/react/common/FullScreenLoader";
import { useIsUnifiedDesignFlagEnabled } from "@/react/hooks/useIsUnifiedDesignFlagEnabled";
import { WelcomeModal } from "@/react/common/components/WelcomeModal";

// Do not export the context directly to prevent consumers from accidentally
// getting the default state since it doesn't match the type definition.
const UserContext = createContext<User>(undefined as any);

export const useCurrentUser = () => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
  const { data, isLoading: isCurrentUserLoading } = useCurrentUserQuery();
  const isUnifiedDesignFlagEnabled = useIsUnifiedDesignFlagEnabled();

  // null indicates the flag is still loading.
  // Ensures we wait for the flag to resolve (to true or false) to avoid a flash of the old UI (bad UX)
  const isUnifiedDesignFlagEnabledLoading = isUnifiedDesignFlagEnabled === null;

  const isLoading = isCurrentUserLoading || isUnifiedDesignFlagEnabledLoading;

  useEffect(() => {
    if (data?.id) {
      Sentry.setUser({
        id: String(data.id),
        role: data?.role,
        organization_id: String(data?.organization?.id),
      });
    }
  }, [data?.id, data?.role, data?.organization?.id]);

  // Don't render children until the user data is successfully loaded.
  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <UserContext.Provider value={data as User}>
      {children}
      {isUnifiedDesignFlagEnabled ? <WelcomeModal /> : null}
    </UserContext.Provider>
  );
};
