import axios from "axios";

import { getErrorMessage } from "@/react/lib/axios-helpers";

import { createAxiosMutation } from "@/react/hooks/react-query";

export const instance = () => {
  const axiosInstance = axios.create({
    baseURL: "/p",
  });

  return axiosInstance;
};

export const useRegenerateAccessCodeMutation = createAxiosMutation(
  async (token: string) => {
    const { data } = await instance().post(`/access-codes/${token}/regenerate`);
    return data;
  },
  {
    successToast: () => ({
      message:
        "Please check your email or text messages for an updated link for this access code.",
    }),
    errorToast: (err) => {
      const message = getErrorMessage(err);
      return {
        message,
      };
    },
  }
);
