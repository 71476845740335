import { useCallback } from "react";
import {
  Form,
  FormCheckbox,
  FormSubmit,
  CheckboxLabel,
} from "@smartrent/forms";
import * as Yup from "yup";
import { match, useHistory } from "react-router";
import { Typography, VStack, Link } from "@smartrent/ui";

import * as links from "@/lib/links";
import { useCreatePasswordMutation } from "@/react/queries/mgmt-api/users/password";
import { UserRole } from "@/react/types/users/user";
import { useIsMobile } from "@/react/hooks/breakpoints";

import { PasswordFields } from "./PasswordFields";

interface ResetFormProps {
  email: string;
  role: UserRole;
  match: match<{
    token: string;
  }>;
}

const createPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Needs a minimum of 8 characters")
    .matches(/[a-z]/, "Needs at least one lowercase letter")
    .matches(/[A-Z]/, "Needs at least one uppercase letter")
    .matches(/\W|_/g, "Needs at least one special character")
    .matches(/[0-9]/, "Needs at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  terms: Yup.boolean()
    .required()
    .oneOf([true], "You must accept Terms and Conditions"),
});

export const ResetForm = ({ email, role, match }: ResetFormProps) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const region = window.location.hostname.includes("intl") ? "intl" : "us";
  // We're using MIX_ENV instead of NODE_ENV because we need to differentiate
  // between QA and PROD but NODE_ENV is the same for both of those environments.
  const deepLinkUrl =
    process.env.MIX_ENV === "prod"
      ? `https://app-links.smartrent.com/links/?link=https://app-links.smartrent.com/invite/${region}/${match.params.token}&apn=com.smartrent.resident&isi=1280670682&ibi=com.smartrent.resident`
      : `https://app-links.smartrent-qa.com/links/?link=https://app-links.smartrent-qa.com/invite/${region}/${match.params.token}&apn=com.smartrent.resident.qa&isi=1485917751&ibi=com.smartrent.resident.qa`;

  if (isMobile && role === UserRole.Consumer) {
    window.location.href = encodeURI(deepLinkUrl);
  }

  const [createPassword] = useCreatePasswordMutation();

  const createPasswordSubmit = useCallback(
    async (values) => {
      await createPassword({
        token: match.params.token,
        confirmPassword: values.confirmPassword,
        newPassword: values.newPassword,
        terms: values.terms,
      });
      history.push("/login");
    },
    [createPassword, match.params.token, history]
  );

  return (
    <Form
      initialValues={{
        newPassword: "",
        confirmPassword: "",
        terms: false,
      }}
      validationSchema={createPasswordValidationSchema}
      onSubmit={createPasswordSubmit}
    >
      <VStack spacing={8}>
        <Typography
          variation="bold.title.three"
          style={{ textAlign: "center" }}
        >
          Create a new password
        </Typography>
        <PasswordFields email={email} match={match} />
        <FormCheckbox
          name="terms"
          label={
            <CheckboxLabel>
              <Typography variation="regular.body.small">
                You acknowledge you agree to our{" "}
              </Typography>
              <Link
                variation="regular.body.small"
                href={links.unauthenticated.terms()}
                target="_blank"
                style={{ textDecorationLine: "underline" }}
              >
                Terms
              </Link>{" "}
              <Typography variation="regular.body.small">
                and have read our{" "}
              </Typography>
              <Link
                variation="regular.body.small"
                href={
                  role === UserRole.HomeOwner
                    ? "https://alloysmarthome.com/privacy/"
                    : "https://smartrent.com/privacy"
                }
                target="_blank"
                style={{ textDecorationLine: "underline" }}
              >
                Privacy Policy
              </Link>{" "}
              <Typography variation="regular.body.small">
                including our use of cookies.
              </Typography>
            </CheckboxLabel>
          }
        />
        <Typography variation="regular.caption.medium" color="textCaption">
          Once you finish creating your account, we recommend that you setup
          2-Factor Authentication within your profile settings.
        </Typography>
        <FormSubmit />
      </VStack>
    </Form>
  );
};
