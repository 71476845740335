import { Organization, User } from "@/react/types";

export enum WebhookTrigger {
  AccessCodeAdded = "access_code_added",
  AccessCodeRemoved = "access_code_removed",
  ContactSensorContactBroken = "contact_sensor_contact_broken",
  DeviceContactSensor = "device_contact_sensor",
  DeviceDeleted = "device_deleted",
  DeviceExcluded = "device_excluded",
  DeviceHumidityAlert = "device_humidity_alert",
  DeviceInstalled = "device_installed",
  DeviceLeakSensor = "device_leak_sensor",
  DeviceLock = "device_lock",
  DeviceLowBattery = "device_low_battery",
  DeviceMotionSensor = "device_motion_sensor",
  DeviceOffline = "device_offline",
  DeviceOnline = "device_online",
  DevicePairedToHub = "device_paired_to_hub",
  DeviceSiren = "device_siren",
  DeviceSwitchMultilevel = "device_switch_multilevel",
  DeviceTamper = "device_tamper",
  DeviceTemperatureAlert = "device_temperature_alert",
  DeviceThermostat = "device_thermostat",
  HubDeleted = "hub_deleted",
  HubInstalled = "hub_installed",
  HubOffline = "hub_offline",
  HubOnline = "hub_online",
  HubZwaveReset = "hub_zwave_reset",
  ManagerGuestAccessCodeUsed = "manager_guest_access_code_used",
  ProspectCreatedOrUpdated = "prospect_created_or_updated",
  RingDoorbellInstalled = "ring_doorbell_installed",
  RingDoorbellVacantUnitEvent = "ring_doorbell_vacant_unit_event",
  TourAccessCodeUsed = "tour_access_code_used",
  TourCancelled = "tour_cancelled",
  TourCompleted = "tour_completed",
  TourCreated = "tour_created",
  TourMotionSensorMotionDetected = "tour_motion_sensor_motion_detected",
  TourNoShow = "tour_no_show",
  TourRescheduled = "tour_rescheduled",
  TourStarted = "tour_started",
  VendorAccessCodeUsed = "vendor_access_code_used",
}

export enum VendorWebhookTrigger {
  DeviceThermostat = "device_thermostat",
  TourAccessCodeUsed = "tour_access_code_used",
  TourCancelled = "tour_cancelled",
  TourCreated = "tour_created",
  TourMotionSensorMotionDetected = "tour_motion_sensor_motion_detected",
  TourStarted = "tour_started",
  TourCompleted = "tour_completed",
  TourRescheduled = "tour_rescheduled",
  TourNoShow = "tour_no_show",
  DeviceLeakSensor = "device_leak_sensor",
}

export type Webhook = {
  id: string;
  organization_id: number;
  organization?: Organization;
  user_id: number;
  user?: User;
  trigger: WebhookTrigger;
  url: string;
  enabled: boolean;
  failed_attempts: number;
  vendor_application_id: number;
  // TODO: vendor_application: VendorApplication; (define this)
  vendor_application?: any;
  deleted_at: string;
  inserted_at: string;
  updated_at: string;
};

export const WebhookTriggerLabels: Record<WebhookTrigger, string> = {
  [WebhookTrigger.AccessCodeAdded]: "Access Code Added",
  [WebhookTrigger.AccessCodeRemoved]: "Access Code Removed",
  [WebhookTrigger.ContactSensorContactBroken]: "Contact Sensor Contact Broken",
  [WebhookTrigger.DeviceContactSensor]: "Device Contact Sensor",
  [WebhookTrigger.DeviceDeleted]: "Device Deleted",
  [WebhookTrigger.DeviceExcluded]: "Device Excluded",
  [WebhookTrigger.DeviceHumidityAlert]: "Device Humidity Alert",
  [WebhookTrigger.DeviceInstalled]: "Device Installed",
  [WebhookTrigger.DeviceLeakSensor]: "Device Leak Sensor",
  [WebhookTrigger.DeviceLock]: "Device Lock",
  [WebhookTrigger.DeviceLowBattery]: "Device Low Battery",
  [WebhookTrigger.DeviceMotionSensor]: "Device Motion Sensor",
  [WebhookTrigger.DeviceOffline]: "Device Offline",
  [WebhookTrigger.DeviceOnline]: "Device Online",
  [WebhookTrigger.DevicePairedToHub]: "Device Paired to Hub",
  [WebhookTrigger.DeviceSwitchMultilevel]: "Device Switch Multilevel",
  [WebhookTrigger.DeviceSiren]: "Device Siren",
  [WebhookTrigger.DeviceTamper]: "Device Tamper",
  [WebhookTrigger.DeviceTemperatureAlert]: "Device Temperature Alert",
  [WebhookTrigger.DeviceThermostat]: "Device Thermostat",
  [WebhookTrigger.HubInstalled]: "Hub Installed",
  [WebhookTrigger.HubOffline]: "Hub Offline",
  [WebhookTrigger.HubOnline]: "Hub Online",
  [WebhookTrigger.HubDeleted]: "Hub Deleted",
  [WebhookTrigger.HubZwaveReset]: "Hub Zwave Reset",
  [WebhookTrigger.ManagerGuestAccessCodeUsed]: "Manager Guest Access Code Used",
  [WebhookTrigger.ProspectCreatedOrUpdated]: "Prospect Created or Updated",
  [WebhookTrigger.RingDoorbellInstalled]: "Ring Doorbell Installed",
  [WebhookTrigger.RingDoorbellVacantUnitEvent]:
    "Ring Doorbell Vacant Unit Event",
  [WebhookTrigger.TourAccessCodeUsed]: "Tour Access Code Used",
  [WebhookTrigger.TourCancelled]: "Tour Cancelled",
  [WebhookTrigger.TourCreated]: "Tour Created",
  [WebhookTrigger.TourMotionSensorMotionDetected]:
    "Tour Motion Sensor Motion Detected",
  [WebhookTrigger.TourNoShow]: "Tour No Show",
  [WebhookTrigger.TourStarted]: "Tour Started",
  [WebhookTrigger.TourCompleted]: "Tour Completed",
  [WebhookTrigger.TourRescheduled]: "Tour Rescheduled",
  [WebhookTrigger.VendorAccessCodeUsed]: "Vendor Access Code Used",
};
