import { useCallback } from "react";
import { Form, FormSubmit } from "@smartrent/forms";
import * as Yup from "yup";
import { match } from "react-router";
import { Box, Typography, VStack } from "@smartrent/ui";

import { useResetPasswordMutation } from "@/react/queries/mgmt-api/users/password";
import { UserRole } from "@/react/types/users/user";

import { PasswordFields } from "@/react/bundles/set_password/PasswordFields";
import { AuthPage } from "@/react/common/AuthPage";

interface ResetFormProps {
  email: string;
  role: UserRole;
  match: match<{
    token: string;
  }>;
}

const resetPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Needs a minimum of 8 characters")
    .matches(/[a-z]/, "Needs at least one lowercase letter")
    .matches(/[A-Z]/, "Needs at least one uppercase letter")
    .matches(/\W|_/g, "Needs at least one special character")
    .matches(/[0-9]/, "Needs at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

export const ResetPassword = ({ email, match }: ResetFormProps) => {
  const [resetUserPassword] = useResetPasswordMutation();

  const resetUserPasswordSubmit = useCallback(
    async (values) => {
      await resetUserPassword({
        token: match.params.token,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });
    },
    [resetUserPassword, match.params.token]
  );

  return (
    <AuthPage>
      <Box>
        <Form
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={resetUserPasswordSubmit}
        >
          <VStack spacing={8}>
            <Typography
              variation="bold.title.three"
              style={{ textAlign: "center" }}
            >
              Create a new password
            </Typography>
            <PasswordFields email={email} match={match} />
            <FormSubmit />
          </VStack>
        </Form>
      </Box>
    </AuthPage>
  );
};
