import { RouteComponentProps } from "react-router-dom";
import { Box } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";
import { AuthPage } from "@/react/common/AuthPage";

import { ResetForm } from "./ResetForm";

interface SetPasswordProps extends RouteComponentProps<{ token: string }> {
  email?: string | null | undefined;
  role: UserRole;
}

const SetPassword = ({ email, role, match }: SetPasswordProps) => {
  return (
    <AuthPage>
      <Box>
        <ResetForm email={email} role={role} match={match} />
      </Box>
    </AuthPage>
  );
};

export default SetPassword;
