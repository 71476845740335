import { useLocation } from "react-router-dom";

import type { Id } from "@/react/types";

/**
 * Preserves the user's group-level context (`groupId`) by appending it to the URL,
 * ensuring it persists when navigating between group and organization-level routes.
 */
export const appendGroupIdToUrl = (
  url: string,
  explicitGroupId?: Id
): string => {
  const searchParams = new URLSearchParams(window.location.search);
  const groupIdSearchParam = searchParams.get("groupId");
  const groupId = explicitGroupId || groupIdSearchParam;

  if (!groupId) {
    return url;
  }

  searchParams.set("groupId", groupId.toString());
  return `${url}?${searchParams.toString()}`;
};

/**
 * Retrieves the `groupId` search parameter from the URL, enabling components to access
 * the current group-level context from the query string.
 */
export const useGroupIdSearchParam = (): string => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get("groupId") || null;
};
