import { useLocation } from "react-router-dom";

import { useFeatureFlagEnabled } from "@/react/queries/mgmt-api/feature-flags";
import { useGroupIdSearchParam } from "@/react/common/utils/append-group-id";

export const useIsUnifiedDesignFlagEnabled = () => {
  const groupIdSearchParam = useGroupIdSearchParam();
  const groupIdFromUrl = useGroupIdFromUrl();

  const { data: unifiedDesignFlag } = useFeatureFlagEnabled(
    {
      flag: "smrt_one_unified_design",
      groupId: groupIdFromUrl || groupIdSearchParam,
    },
    {
      initialData: { enabled: null },
    }
  );

  const isLoading = unifiedDesignFlag.enabled === null;

  if (isLoading) {
    return null;
  }

  // Adjust body padding based on flag state
  document.body.style.paddingTop = unifiedDesignFlag.enabled ? "0" : "";

  return unifiedDesignFlag.enabled;
};

export const useSidebarWidth = () => {
  const isUnifiedDesignFlagEnabled = useIsUnifiedDesignFlagEnabled();
  return isUnifiedDesignFlagEnabled ? 250 : 300;
};

const useGroupIdFromUrl = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const groupIdIndex = pathSegments.indexOf("groups") + 1;
  const groupIdParam = groupIdIndex > 0 ? pathSegments[groupIdIndex] : null;
  if (groupIdParam === null || isNaN(Number(groupIdParam))) {
    return null;
  }

  return groupIdParam;
};
