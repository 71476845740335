import { StyleSheet } from "react-native";
import { match } from "react-router-dom";
import { Typography, VStack } from "@smartrent/ui";
import { FormPasswordInputField } from "@smartrent/forms";

interface PasswordFieldsProps {
  email?: string;
  match: match<{
    token: string;
  }>;
}

export const PasswordFields = ({ email, match }: PasswordFieldsProps) => {
  return (
    <>
      <VStack>
        {email ? (
          <Typography variation="bold.title.six">Email</Typography>
        ) : null}
        <Typography style={styles.email} variation="regular.body.medium">
          {email}
        </Typography>
      </VStack>
      {match.path.includes("change-password") ? (
        <FormPasswordInputField
          submitOnEnter
          name="currentPassword"
          label="Current Password"
        />
      ) : null}
      <FormPasswordInputField name="newPassword" label="New Password" />
      <FormPasswordInputField
        submitOnEnter
        name="confirmPassword"
        label="Confirm Password"
      />
    </>
  );
};

const styles = StyleSheet.create({
  email: { marginBottom: 8 },
});
